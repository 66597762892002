<template>
  <div>
    <v-container grid-list-xs>
       <v-row>
        <v-col cols="12">
          <vue-friendly-iframe 
            target="_blank" 
            :src="`https://agentos.typeform.com/to/Wrkxhz?name=${this.$store.state.activeUser}&source=${this.$store.state.user.ClientName}`"
          >
          </vue-friendly-iframe>
        </v-col>
      </v-row>     
    </v-container>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="css" >

  .vue-friendly-iframe iframe {
    width : 100%;
    height: 80vh;
  }

</style>